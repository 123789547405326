import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'

/**
 * Import all page components here
 */
import LoginPage from './pages/login-page'
import Lister from './pages/schedules-page'
import NewSchedule from './pages/new-schedule-page'
import Planner from './pages/planner'
import './App.css'

function AppRouter() {
  return (
    <Router>
      <Route exact path='/' component={LoginPage} />
      <Route path='/planner' component={Planner} />
      <Route path='/schedules' component={Lister} />
      <Route path='/schedules-new/' component={NewSchedule} />
    </Router>
  )
}

export default AppRouter
