import React, { useMemo, useState } from 'react'
import GroupedMultiSelectOption from './GroupedMultiSelectOption'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import useFocus from '../../../hooks/useFocus'

export type OptionType<T> = {
  key: string
  value: T
}
export type OptionsType<T> = OptionType<T>[]
export type OptionsGroup<T> = {
  label: string
  options: OptionsType<T>
}
export type GroupedMultiSelectProps<T> = {
  optionGroups: OptionsGroup<T>[]
  selectedOptions: OptionsType<T>
  setSelectedOptions: (options: OptionsType<T>) => void
}
const GroupedMultiSelect = <T,>({
  optionGroups,
  selectedOptions,
  setSelectedOptions
}: GroupedMultiSelectProps<T>): JSX.Element => {
  const [inputRef, setFocusOnInput] = useFocus()
  const [focus, setFocus] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [filteredOptionGroups, setFilteredOptionGroups] =
    useState<OptionsGroup<T>[]>(optionGroups)
  useMemo(() => {
    setFilteredOptionGroups(
      optionGroups.map((optionGroup) => ({
        ...optionGroup,
        options: optionGroup.options.filter(
          (option) =>
            option.key.toLowerCase().includes(query.toLowerCase()) &&
            !selectedOptions.map((x) => x.key).includes(option.key)
        )
      }))
    )
  }, [optionGroups, selectedOptions, query])
  return (
    <div className='relative'>
      <div
        className='flex flex-wrap border p-2 border-lightGrey rounded-md mb-1 gap-1 cursor-text'
        onClick={setFocusOnInput}
      >
        {selectedOptions.map((selectedOption, index) => (
          <div
            key={index}
            className='flex gap-2 cursor-pointer text-xs items-center bg-blue rounded-lg text-white px-2 py-1'
            onMouseDown={() => {
              setSelectedOptions([
                ...selectedOptions.filter(
                  (option) => option.key !== selectedOption.key
                )
              ])
            }}
          >
            <p className='mb-0'>{selectedOption.key}</p>
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        ))}
        <input
          className='outline-none flex-1'
          type='text'
          value={query}
          ref={inputRef}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
      <div className='flex absolute w-full z-10'>
        {focus && (
          <div className='border p-2 border-lightGrey rounded-md min-w-full max-h-64 overflow-y-auto bg-white'>
            {filteredOptionGroups.map((optionGroup, index) => (
              <GroupedMultiSelectOption
                optionGroup={optionGroup}
                onOptionSelect={(option) =>
                  setSelectedOptions([...selectedOptions, option])
                }
                key={index}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default GroupedMultiSelect
