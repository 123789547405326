import React, { useState } from 'react'
import { OptionsGroup, OptionType } from './GroupedMultiSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'

type GroupedMultiSelectOptionProps<T> = {
  optionGroup: OptionsGroup<T>
  onOptionSelect: (option: OptionType<T>) => void
}
const GroupedMultiSelectOption = <T,>({
  optionGroup,
  onOptionSelect
}: GroupedMultiSelectOptionProps<T>): JSX.Element => {
  const [hover, setHover] = useState<boolean>(false)
  return (
    <div className='relative min-w-4'>
      <div
        onMouseDown={(e) => {
          e.preventDefault()
          setHover(!hover)
        }}
        className='flex hover:bg-gray-100 items-center gap-2 justify-between cursor-pointer'
      >
        <p className='text-sm overflow-ellipsis mb-0'>
          {optionGroup.label} ({optionGroup.options.length})
        </p>
        <FontAwesomeIcon icon={hover ? faCaretDown : faCaretRight} />
      </div>
      {hover && (
        <div className='relative'>
          {optionGroup.options.map((option, index) => (
            <div
              className='hover:bg-gray-100 pl-4 cursor-pointer'
              onMouseDown={(e) => {
                e.preventDefault()
                onOptionSelect(option)
              }}
              key={index}
            >
              <p className='text-sm overflow-ellipsis mb-0 text-left'>
                {option.key}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default GroupedMultiSelectOption
