import React, { Component } from 'react'
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Row,
  Col,
  FormControl,
  Container
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashAlt,
  faSave,
  faFileExport,
  faFileImport,
  faSearchPlus,
  faSearchMinus,
  faCog,
  faCopy,
  faBell
} from '@fortawesome/free-solid-svg-icons'

class UtilityButtons extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Col>
        <ButtonToolbar aria-label='Toolbar with button groups'>
          <ButtonGroup aria-label='First group' style={{ marginLeft: 'auto' }}>
            <Button
              style={{ fontSize: '1rem' }}
              onClick={this.props.SaveCalendar}
            >
              {this.props.isSaving ? '...' : <FontAwesomeIcon icon={faSave} />}
            </Button>
          </ButtonGroup>
          <ButtonGroup aria-label='First group' style={{ marginLeft: 'auto' }}>
            <Button
              onClick={() => this.props.ZoomCalendar(true)}
              style={{ verticalAlign: 'middle', marginTop: 'auto' }}
            >
              <FontAwesomeIcon icon={faSearchPlus} />
            </Button>
            <Button
              onClick={() => this.props.ZoomCalendar(false)}
              style={{ verticalAlign: 'middle', marginTop: 'auto' }}
            >
              <FontAwesomeIcon icon={faSearchMinus} />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Col>
    )
  }
}

export default UtilityButtons
