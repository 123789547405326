import React, { Component } from 'react'
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Row,
  Col,
  FormControl,
  Container
} from 'react-bootstrap'

class CampaignCard extends React.Component {
  constructor(props) {
    super(props)
  }

  GetCampaignColor = (str) => {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    var c = (hash & 0x00ffffff).toString(16).toUpperCase()

    return '00000'.substring(0, 6 - c.length) + c
  }

  render() {
    return (
      <Row
        className='campaignCard'
        style={
          this.props.index === 0
            ? { borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }
            : this.props.index === this.props.campaigns.length - 1
            ? {
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px'
              }
            : null
        }
      >
        <Col className='campaignCell' xs={0.5}>
          <div
            style={{
              height: 25,
              width: 25,
              backgroundColor:
                '#' + this.GetCampaignColor(this.props.item.name),
              marginTop: 7,
              borderRadius: 5
            }}
          ></div>
        </Col>
        <Col className='campaignCell'>
          <div>
            <b>Kampagne:</b> {this.props.item.name}
            <br />
            <b>Slutdato:</b> {this.props.item.end_date}
          </div>
        </Col>

        <Col className='campaignCell'>
          <b>Næste push:</b> {this.props.item.next_push}
          <br />
          <b>Næste point-reset:</b> {this.props.item.next_reset}
          <br />
          <b>Sidste point-reset:</b> {this.props.item.prev_reset}
        </Col>
        <Col className='campaignCell' style={{ borderWidth: '0' }}>
          <b>Seneste survey:</b> {this.props.item.latest_survey}
          <br />
          <b>Næste survey:</b> {this.props.item.next_survey}
        </Col>
      </Row>
    )
  }
}

export default CampaignCard
