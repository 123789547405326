import { RefObject, useRef } from 'react'

const useFocus = (): [RefObject<HTMLInputElement>, () => void] => {
  const inputRef = useRef<HTMLInputElement>(null)
  const setFocusOnRef = () => {
    inputRef?.current?.focus()
  }
  return [inputRef, setFocusOnRef]
}

export default useFocus
