import React, { Component } from 'react'
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Row,
  Col,
  FormControl,
  Container
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashAlt,
  faSave,
  faFileExport,
  faFileImport,
  faSearchPlus,
  faSearchMinus,
  faCog,
  faCopy,
  faBell
} from '@fortawesome/free-solid-svg-icons'
import Searchable from 'react-searchable-dropdown'

class TemplateSearch extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Searchable
        value='' //if value is not item of options array, it would be ignored on mount
        placeholder='Find template..' // by default "Search"
        notFoundText='Intet resultat' // by default "No result found"
        options={this.props.templates.map((option) => {
          return { value: option.id, label: option.title }
        })}
        onSelect={this.props.select}
        listMaxHeight={200} //by default 140
      />
    )
  }
}

export default TemplateSearch
