import Axios from 'axios'
import { Category } from './types'
// const ApiBaseUrl = "https://easymove-be.herokuapp.com";
const ApiBaseUrl = process.env.REACT_APP_BACKEND_URL
if (ApiBaseUrl === undefined) console.error('Could not set ApiBaseUrl')
// const ApiBaseUrl = "https://easymove-staging.herokuapp.com";
// const ApiBaseUrl = "http://192.168.0.22:8000";

const Api = {
  NewSchedule: (data: unknown): unknown => {
    return fetchFromServer('POST', 'easyadmin/schedules/new/', data)
  },
  NewScheduleInfo: (): unknown => {
    return fetchFromServer('GET', 'easyadmin/schedules/new/')
  },
  EditScheduleInfo: (): unknown => {
    return fetchFromServer(
      'GET',
      'easyadmin/schedules/edit/' + localStorage.getItem('scheduleId') + '/'
    )
  },
  EditSchedule: (data: unknown): unknown => {
    return fetchFromServer(
      'POST',
      'easyadmin/schedules/edit/' + localStorage.getItem('scheduleId') + '/',
      data
    )
  },
  getInfo: (): unknown => {
    return fetchFromServer(
      'GET',
      'easyadmin/planner/' + localStorage.getItem('scheduleId') + '/'
    )
  },
  getSchedules: (): unknown => {
    return fetchFromServer('GET', 'easyadmin/schedules/')
  },
  updateInfo: (data: unknown): unknown => {
    return fetchFromServer(
      'POST',
      'easyadmin/planner/' + localStorage.getItem('scheduleId') + '/',
      data
    )
  },
  createTemplate: (data: unknown): unknown => {
    return fetchFromServer('POST', 'easyadmin/planner-template/', data)
  },
  deleteTemplate: (data: unknown): unknown => {
    return fetchFromServer('DELETE', 'easyadmin/planner-template/', data)
  },
  deleteSchedule: (data: unknown): unknown => {
    return fetchFromServer('DELETE', 'easyadmin/schedules/', data)
  },
  getDetails: (): unknown => {
    return fetchFromServer('GET', 'dash/v2/api/')
  },
  getToken: async (
    username: string,
    password: string
  ): Promise<boolean | undefined> => {
    return getToken(username, password)
  }
}

const getToken = async (
  username: string,
  password: string
): Promise<boolean | undefined> => {
  // let url = "https://easymove-be.herokuapp.com/dashapi/token/"
  // let url ="https://easymove-staging.herokuapp.com/dashapi/token/"
  const url = ApiBaseUrl + '/dashapi/token/'
  const data = {
    username,
    password
  }

  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }

  return fetch(url, options).then((response) => {
    const saveToken = async (response: Response) => {
      return response.text().then((content) => {
        localStorage.setItem('access-token', JSON.parse(content).access)
        localStorage.setItem('refresh-token', JSON.parse(content).refresh)
      })
    }
    switch (response.status) {
      case 200:
        console.log('Status: 200 \t Obtaining tokens successful')
        saveToken(response)
        return true
      case 201:
        console.log('Status: 200 \t Obtaining tokens successful')
        saveToken(response)
        return true
      case 401:
        console.log('Status: 401 \t Obtaining tokens failed')
        return false
      case 403:
        console.log('Status: 403 \t Obtaining tokens failed')
        return false
      default:
        console.error('Reached default in login-switch')
        break
    }
  })
}

let refreshing = false

const refreshToken = () => {
  if (!refreshing) {
    refreshing = true
    console.log('Refreshing token')
    // let url = "https://easymove-be.herokuapp.com/dashapi/token/refresh/"
    // let url ="https://easymove-staging.herokuapp.com/dashapi/token/refresh/"
    const url = ApiBaseUrl + '/dashapi/token/refresh/'
    const data = {
      refresh: localStorage.getItem('refresh-token')
    }

    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return fetch(url, options).then(async (response) => {
      const saveToken = async (response: Response) => {
        return response.text().then((content) => {
          localStorage.setItem('access-token', JSON.parse(content).access)
        })
      }
      switch (response.status) {
        case 200:
          console.log('Status: 200 \t Refreshing token successful')
          await saveToken(response)
          refreshing = false
          return true
        case 201:
          console.log('Status: 201 \t Refreshing token successful')
          await saveToken(response)
          refreshing = false
          return true
        case 401:
          console.log('Status: 401 \t Refreshing token failed')
          refreshing = true
          window.location.assign('/')
          return false
        default:
          console.error(
            'Reached default in refresh-switch with status code: ' +
              response.status
          )
          refreshing = false
          console.error(response)
          break
      }
    })
  }
}

const fetchFromServer = async (
  method: string,
  path: string,
  data?: unknown
): Promise<unknown> => {
  console.log('Fetching from server')
  // let url = "https://easymove-be.herokuapp.com/";
  // let url ="https://easymove-staging.herokuapp.com/"
  const url = ApiBaseUrl + '/'
  const options = {
    method,
    body: typeof data !== 'undefined' ? JSON.stringify(data) : void 0,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access-token'),
      'Content-Type': 'application/json'
    }
  }

  console.log(options)
  console.log(url + path)

  const response = await fetch(url + path, options)
  switch (response.status) {
    case 200:
      console.log('Fetching from server successful')
      return response.text()
    case 201:
      console.log('Fetching from server successful')
      return response.text()
    case 401:
      console.log('Fetching from server failed')
      await refreshToken()
      console.log('Trying fetch again after refresh')
      return fetchFromServer(method, path, data)
    default:
      console.error('FINAL | Failed fetching from server')
      console.error(response)
      break
  }
}

export default Api

// React-Query queries
const axios = Axios.create({
  baseURL: ApiBaseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access-token')
  if (config.headers && token) {
    config.headers.Authorization = token ? `Bearer ${token}` : ''
  }
  return config
})
type SuccessResponse<R> = { ok: R }
export const getNewToken = async (): Promise<string> => {
  const data = {
    refresh: localStorage.getItem('refresh-token')
  }
  if (data.refresh === null && !process?.env.STORYBOOK) {
    window.location.assign('/')
    return '' //unreachable
  }
  type TokenResponse = {
    data: {
      access: string
      refresh: string
    }
  }
  type TokenRequest = {
    refresh: string
  }
  const res = await axios.post<TokenRequest, TokenResponse>(
    `${ApiBaseUrl}/dashapi/token/refresh/`,
    data as TokenRequest
  )
  localStorage.setItem('access-token', res.data.access)
  return res.data.access
}
export const getCategories = async (): Promise<Category[]> => {
  return (
    await axios.get<SuccessResponse<Category[]>>(`${ApiBaseUrl}/v2/categories`)
  ).data.ok
}
