import React, { useEffect, useState } from 'react'
import { Activity, ActivityExtendedProps, Bonus, Event } from '../../types'
import { CirclePicker } from 'react-color'
import GroupedMultiSelect, {
  OptionsGroup
} from './multi-select/GroupedMultiSelect'
import { useQuery } from 'react-query'
import { getCategories, getNewToken } from '../../Api'

export type EditEventSubset = Pick<
  Event<ActivityExtendedProps>,
  'title' | 'extendedProps' | 'backgroundColor'
>

type ActivitySubset = Pick<Activity, 'id' | 'title_en' | 'category_id'>
type BonusSubset = Pick<Bonus, 'id' | 'name'>
export type EditEventBlockProps = {
  selectedEvent: EditEventSubset
  onSelectedEventChange: (event: EditEventSubset) => void
  activities: ActivitySubset[]
  bonuses: BonusSubset[]
}
const EditEventBlock: React.VFC<EditEventBlockProps> = ({
  selectedEvent,
  onSelectedEventChange,
  activities,
  bonuses
}) => {
  const [pickingColor, setPickingColor] = useState<boolean>(false)
  const [activityOptionGroups, setActivityOptionGroups] = useState<
    OptionsGroup<ActivitySubset>[]
  >([])
  const tokenQuery = useQuery('token', getNewToken)
  const categoriesQuery = useQuery('categories', getCategories, {
    enabled: !!tokenQuery.data
  })
  useEffect(() => {
    if (categoriesQuery.isSuccess) {
      setActivityOptionGroups(
        categoriesQuery.data?.map((category) => ({
          label: category.name,
          options: activities
            .filter((activity) => activity.category_id === category.id)
            .map((activity) => ({
              key: activity.title_en,
              value: activity
            }))
        }))
      )
    }
  }, [activities, categoriesQuery.dataUpdatedAt])
  return (
    <div className='infoView'>
      <div className='flex gap-2'>
        <div className='flex flex-col max-w-64'>
          Titel
          <input
            type='text'
            className='border border-gray rounded-md p-2'
            maxLength={20}
            value={selectedEvent.title}
            onChange={(e) =>
              onSelectedEventChange({ ...selectedEvent, title: e.target.value })
            }
          />
          {pickingColor ? (
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: '1rem',
                zIndex: 2,
                position: 'relative',
                width: 'fit-content',
                padding: 5
              }}
            >
              <CirclePicker
                color={selectedEvent.backgroundColor}
                onChange={(color: { hex: string }) => {
                  onSelectedEventChange({
                    ...selectedEvent,
                    backgroundColor: color.hex
                  })
                  setPickingColor(false)
                }}
              />
            </div>
          ) : (
            <div>
              <div
                className='color-square'
                style={{
                  backgroundColor: selectedEvent.backgroundColor ?? '#3888d8'
                }}
                onClick={() => setPickingColor(true)}
              ></div>
            </div>
          )}
        </div>
        <div className='flex flex-col max-w-64'>
          Aktiviteter
          <GroupedMultiSelect
            optionGroups={activityOptionGroups}
            selectedOptions={selectedEvent.extendedProps.activities.map(
              (activity) => ({ key: activity.title_en, value: activity })
            )}
            setSelectedOptions={(activities) =>
              onSelectedEventChange({
                ...selectedEvent,
                extendedProps: {
                  ...selectedEvent.extendedProps,
                  activities: activities.map((activity) => activity.value)
                }
              })
            }
          />
        </div>
        <div className='flex flex-col max-w-64'>
          Featured aktiviteter
          <GroupedMultiSelect
            optionGroups={activityOptionGroups}
            selectedOptions={selectedEvent.extendedProps.factivities.map(
              (activity) => ({ key: activity.title_en, value: activity })
            )}
            setSelectedOptions={(activities) =>
              onSelectedEventChange({
                ...selectedEvent,
                extendedProps: {
                  ...selectedEvent.extendedProps,
                  factivities: activities.map((activity) => activity.value)
                }
              })
            }
          />
        </div>
        <div className='flex flex-col max-w-64'>
          Bonus objekter
          <GroupedMultiSelect
            optionGroups={[
              {
                label: 'Bonus',
                options: bonuses.map((bonus) => ({
                  key: bonus.name,
                  value: bonus
                }))
              }
            ]}
            selectedOptions={selectedEvent.extendedProps.bonus.map((bonus) => ({
              key: bonus.name,
              value: bonus
            }))}
            setSelectedOptions={(bonuses) =>
              onSelectedEventChange({
                ...selectedEvent,
                extendedProps: {
                  ...selectedEvent.extendedProps,
                  bonus: bonuses.map((bonus) => bonus.value)
                }
              })
            }
          />
        </div>
      </div>
    </div>
  )
}
export default EditEventBlock
