import React from 'react'
import './App.css'
import Router from './router'
import { QueryClient, QueryClientProvider } from 'react-query'

// Create a client
const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <div className='App'>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </div>
  )
}

export default App
